"use client";
import Image from "next/image";
import dynamic from "next/dynamic";
import Link from "next/link";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";

// import ContactUsForm from "./ContactUsForm";

const ContactUsForm = dynamic(() => import("./ContactUsForm"), {
  ssr: false,
});

const Footer = () => {
  return (
    <div
      id="footer"
      className=" bg-gray-800 text-gray-400 md:text-base"
      suppressHydrationWarning={true}
    >
      <section className="py-10 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 md:gap-6 lg:gap-20 items-start justify-between">
            <div className="col-span-1 lg:col-span-2">
              <div className="mb-4 md:mb-6">
                <a
                  href="#"
                  className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                  <img
                    src="../images/footer.png"
                    className="object-cover w-44 lg:w-80"
                    alt="Logo"
                  />
                </a>
              </div>
              <p className="text-white">
                Touchkey is a sleek, standalone biometric reader that connects
                to an external controller for locking main doors and wardrobes.
              </p>

              <div className="mt-6">
                <div className="flex items-start mb-6">
                  <img
                    src="/map.svg"
                    className="w-8 h-8 mr-4 self-start"
                    alt="location"
                  />
                  <div className="text-white">
                    325, Pioneer Industrial Estate, Subhash Road, Jogeshwari
                    (E), Mumbai-400060. India
                  </div>
                </div>
                <div className="flex items-start mb-4">
                  <img
                    src="email.svg"
                    className="w-8 h-8 mr-4 self-start"
                    alt="email"
                  />
                  <div className="text-white">sales@acetech-india.com</div>
                </div>
                <div className="flex items-start mb-4">
                  <img
                    src="phone.svg"
                    className="w-8 h-8 mr-4 self-start"
                    alt="phone"
                  />
                  <div className="text-white">022-46016262</div>
                </div>
              </div>
            </div>
            <div className="col-span-1 space-y-2">
              <h5 className="text-white mb-4 md:mb-6">Quick Links</h5>
              <div>
                <a href="/" className="text-white hover:text-white">
                  Home
                </a>
              </div>
              <div>
                <a
                  href="/touchkey-fingerprint-reader/"
                  className="text-white hover:text-white"
                >
                  Touchkey Fingerprint Reader
                </a>
              </div>
              <div>
                <a href="/biohandle/" className="text-white hover:text-white">
                  Biohandle
                </a>
              </div>
            </div>
            <div className="col-span-full lg:col-span-2">
              <h5 className="text-white mb-4 md:mb-6">Enquiry</h5>
              <p className="text-white">
                For queries related to product enquiries, collaborations or
                dealerships/partnerships, please fill and submit the form below
                and we will get back to you.
              </p>
              <ContactUsForm suppressHydrationWarning={true} />
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 md:py-8 border-y border-white border-opacity-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-6 items-center justify-between text-gray-400">
            <div className="col-span-1">
              <p className="text-white">
                Copyright © 2024. Touchkey. All Rights Reserved.
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-slate-200">
                <Link
                  href="https://prateekshawebdesign.com"
                  className="text-slate-500"
                >
                  Website Designed by Prateeksha Web Design
                </Link>
              </p>
            </div>
            <div className="col-span-1">
              <div className="flex items-center justify-center lg:justify-end space-x-3">
                <Link href="#">
                  <FaFacebook size={25} className="text-white" />
                </Link>
                <Link href="#">
                  <FaInstagramSquare size={25} className="text-white" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
