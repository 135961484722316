"use client";
import type { Metadata } from "next";
import "./globals.scss";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import StickyHeader from "@/components/StickyHeader";
//import Floting from "@/components/Floting";
import FloatingWhatsAppButton from "@/components/FloatingWhatsAppButton";
import GoogleAnalyticsComponent from "@/components/GoogleAnalyticsComponent";

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en" suppressHydrationWarning={true}>
      <body>
        <StickyHeader />
        {children}
        <Footer />
        <div>{/* <Floting /> */}</div>
        <div className="fixed left-5">
          <FloatingWhatsAppButton />
        </div>
        <GoogleAnalyticsComponent />
      </body>
    </html>
  );
}
