// components/FloatingWhatsAppButton.js
import React from "react";
import Link from "next/link";
import { ImWhatsapp } from "react-icons/im";

const FloatingWhatsAppButton = () => {
  return (
    <div className="fixed bottom-5 right-8 bg-green-400 rounded-full z-50">
      <Link href="https://wa.me/919769011540" passHref target="_blank">
        <ImWhatsapp size={60} className="text-white p-3" />
      </Link>
    </div>
  );
};

export default FloatingWhatsAppButton;
